<template>
  <main v-if="auth0Status() == 'expiredPassword'">
    <div class="message">
      <h2>パスワードの有効期限が切れています。</h2>
      パスワード設定ページのご案内を送信します。
    </div>
    <input type="button" class="send-button" value="メールを送信" @click="sendPasswordChangeLink" />
    <div>{{ message }}</div>
  </main>

  <main v-else-if="auth0Status() == 'newUser'">
    <div class="message">
      <h2>初回ログインのため、パスワードを変更してください。</h2>
      パスワード設定ページのご案内を送信します。
    </div>
    <input type="button" class="send-button" value="メールを送信" @click="sendPasswordChangeLink" />
    <div>{{ message }}</div>
  </main>

  <main v-else>
    <vue-loading type="bars" color="#d9544e" :size="{ width: '70px', height: '70px' }"></vue-loading>
  </main>
</template>

<script>
import { VueLoading } from 'vue-loading-template';
import axios from 'axios';

export default {
  name: 'ChangePassword',
  components: { VueLoading },
  data() {
    return {
      message: '',
    };
  },
  created() {},
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    auth0Status() {
      let ret;
      if (!this.$auth.loading && !this.$auth.isAuthenticated) {
        window.location.href = '/';
      } else if (!this.$auth.loading && this.$auth.isAuthenticated) {
        if (this.$auth.user['https://bi-ph2.jmdc-qi.com/lastreset'] >= '90') {
          ret = 'expiredPassword';
        } else if (this.$auth.user['https://bi-ph2.jmdc-qi.com/lastreset'] == '-1') {
          ret = 'newUser';
        } else {
          window.location.href = '/';
        }
      } else {
        ret = 'lodding';
      }
      return ret;
    },
    sendPasswordChangeLink() {
      let options = {
        method: 'POST',
        url: 'https://jmdcms.au.auth0.com/dbconnections/change_password',
        headers: { 'content-type': 'application/json' },
        data: {
          client_id: 'Peji0IMCUP5s5IudVCkD8QmAAXxO2LoB',
          email: this.$auth.user.email,
          connection: 'jmdcqi-user',
        },
      };

      axios
        .request(options)
        .then(function(response) {
          console.log(response.data);
        })
        .catch(function(error) {
          console.error(error);
        });
      this.message = 'メールを送信しました。'
    },
  },
};
</script>

<style scoped lang="scss">
main {
  align-items: center;
  justify-content: space-between;
  margin: auto;
  font-size: 1.8rem;
  .send-button {
    color: #fff;
    background: #e66446;
    border: 1px solid #e66446;
    padding: 18px 30px;
    border-radius: 10px;
    text-decoration: none;
    font-size: 2rem;
    display: inline-block;
    margin-top: 30px;
  }
}
</style>
